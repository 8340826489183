// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-item-page{
    background-image: linear-gradient(rgb(193, 192, 207), rgb(98, 49, 176));
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/styles/actionItem.css"],"names":[],"mappings":"AAAA;IACI,uEAAuE;IACvE,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".action-item-page{\n    background-image: linear-gradient(rgb(193, 192, 207), rgb(98, 49, 176));\n    background-size: cover;\n    background-position: center;\n    height: 100vh;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
