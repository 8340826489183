// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/images/background.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing{
    /* background-image: url("https://images.pexels.com/photos/333513/pexels-photo-333513.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"); */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.header{
    background-color: #ffffff;
    border-radius: 5px;
    height: 60px;
}

.logo-space img{
    height: 50px;
    border-radius: 35px;
    margin: 5px
}

.intro-context-parent{
    padding-top: 5%;
}

.intro-context{    
    margin: 20px;
    padding: 1px;
    font-size: 25px;
    font-weight: 500;
    color: #808080;
    text-align: justify;
}

.intro-context:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

.started-button :hover{
    box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2);
}

button{
    padding-left: 60%;
    width: 100%;
    background-color: #ffffff;
    color: #000000;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.intro-started{
    border-radius: 10px;
    font-size: large;
}`, "",{"version":3,"sources":["webpack://./src/styles/landing.css"],"names":[],"mappings":"AAAA;IACI,4IAA4I;IAC5I,yDAA4D;IAC5D,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB;AACJ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,wCAAwC;EAC1C;;AAEF;IACI,iDAAiD;AACrD;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".landing{\n    /* background-image: url(\"https://images.pexels.com/photos/333513/pexels-photo-333513.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2\"); */\n    background-image: url(\"../../public/images/background.jpeg\");\n    background-size: cover;\n    background-position: center;\n    height: 100vh;\n}\n\n.header{\n    background-color: #ffffff;\n    border-radius: 5px;\n    height: 60px;\n}\n\n.logo-space img{\n    height: 50px;\n    border-radius: 35px;\n    margin: 5px\n}\n\n.intro-context-parent{\n    padding-top: 5%;\n}\n\n.intro-context{    \n    margin: 20px;\n    padding: 1px;\n    font-size: 25px;\n    font-weight: 500;\n    color: #808080;\n    text-align: justify;\n}\n\n.intro-context:hover {\n    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);\n  }\n\n.started-button :hover{\n    box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2);\n}\n\nbutton{\n    padding-left: 60%;\n    width: 100%;\n    background-color: #ffffff;\n    color: #000000;\n    padding: 15px 0;\n    font-size: 18px;\n    font-weight: 600;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.intro-started{\n    border-radius: 10px;\n    font-size: large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
