// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments{
    border: 1px solid black;
    background-color: white;
    margin-bottom: 10px;
}

.profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #512DA8;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    /* line-height: 150px; */
    margin: 20px 0;
  }
  
.comment-header{
    display: flex;
    align-items: center;
}

.comment-header-username{
    margin-left: 10px;
    font-size: 17px;
    font-weight: 600;
}

.comment-header-date{
    margin-left: auto;
}

.comment-preview{
    margin-left: 60px;
    font-family:Arial, Helvetica, sans-serif;
}

.comment-footer{
    display: flex;
    padding-left: 80%;
}

.comment-footer i{
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 18px;
}

.comment-footer-edit{

}

.comment-footer-heart{
}`, "",{"version":3,"sources":["webpack://./src/styles/comments.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;IACxB,cAAc;EAChB;;AAEF;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;;AAEA;;AAEA;AACA","sourcesContent":[".comments{\n    border: 1px solid black;\n    background-color: white;\n    margin-bottom: 10px;\n}\n\n.profile-image {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    background: #512DA8;\n    font-size: 20px;\n    color: #fff;\n    text-align: center;\n    line-height: 50px;\n    /* line-height: 150px; */\n    margin: 20px 0;\n  }\n  \n.comment-header{\n    display: flex;\n    align-items: center;\n}\n\n.comment-header-username{\n    margin-left: 10px;\n    font-size: 17px;\n    font-weight: 600;\n}\n\n.comment-header-date{\n    margin-left: auto;\n}\n\n.comment-preview{\n    margin-left: 60px;\n    font-family:Arial, Helvetica, sans-serif;\n}\n\n.comment-footer{\n    display: flex;\n    padding-left: 80%;\n}\n\n.comment-footer i{\n    padding-left: 20px;\n    padding-bottom: 20px;\n    padding-top: 20px;\n    font-size: 18px;\n}\n\n.comment-footer-edit{\n\n}\n\n.comment-footer-heart{\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
