// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-item{
    width: 100%;
}

.home-page{
    /* background-image: url("https://images.pexels.com/photos/333513/pexels-photo-333513.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
    
    
    background-color: white; */
    /* background-size: cover; */
    background-image: linear-gradient(rgb(193, 192, 207), rgb(98, 49, 176));
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/styles/home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI;;;8BAG0B;IAC1B,4BAA4B;IAC5B,uEAAuE;IACvE,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".add-new-item{\n    width: 100%;\n}\n\n.home-page{\n    /* background-image: url(\"https://images.pexels.com/photos/333513/pexels-photo-333513.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2\");\n    \n    \n    background-color: white; */\n    /* background-size: cover; */\n    background-image: linear-gradient(rgb(193, 192, 207), rgb(98, 49, 176));\n    background-size: cover;\n    background-position: center;\n    height: 100vh;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
