// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.user-header{
    border-bottom: 2px solid black;
}


.search-form{
    margin-left: 350px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.search-input{
    border-radius: 10px;
    border-color: rgb(123, 117, 117);
    background-color: rgb(185,180,205);
    border: none;
    width: 300px;
    font-size: medium;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}

.search-input:focus {
    outline-color: rgb(196, 186, 186);
    background-color: white;
  }

.search-input::placeholder{
    color: rgb(0, 0, 0);
    font-size: large;
}

.search-icon{
    margin-left: 10px;
    font-size: 22px;
    padding-top: 20px;
}

.header-profile-dropdown{
    display: block;
    position: absolute;
    background-color: white;
    border: 2px solid black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.header-profile-link{
    text-decoration: none;
    color: black;    
    height: 20px;
}

.header-profile-link-container{
    padding: 4px;
    border-bottom: 1px solid black;
}

.header-profile-link-container::selection {
    background-color: transparent; 
    color: inherit; 
  }
`, "",{"version":3,"sources":["webpack://./src/styles/userHeader.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;AAClC;;;AAGA;IACI,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iCAAiC;IACjC,uBAAuB;EACzB;;AAEF;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,uBAAuB;IACvB,uBAAuB;IACvB,6EAA6E;AACjF;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,6BAA6B;IAC7B,cAAc;EAChB","sourcesContent":["\n.user-header{\n    border-bottom: 2px solid black;\n}\n\n\n.search-form{\n    margin-left: 350px;\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.search-input{\n    border-radius: 10px;\n    border-color: rgb(123, 117, 117);\n    background-color: rgb(185,180,205);\n    border: none;\n    width: 300px;\n    font-size: medium;\n    height: 40px;\n    padding-left: 20px;\n    padding-right: 20px;\n    position: relative;\n}\n\n.search-input:focus {\n    outline-color: rgb(196, 186, 186);\n    background-color: white;\n  }\n\n.search-input::placeholder{\n    color: rgb(0, 0, 0);\n    font-size: large;\n}\n\n.search-icon{\n    margin-left: 10px;\n    font-size: 22px;\n    padding-top: 20px;\n}\n\n.header-profile-dropdown{\n    display: block;\n    position: absolute;\n    background-color: white;\n    border: 2px solid black;\n    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n}\n\n.header-profile-link{\n    text-decoration: none;\n    color: black;    \n    height: 20px;\n}\n\n.header-profile-link-container{\n    padding: 4px;\n    border-bottom: 1px solid black;\n}\n\n.header-profile-link-container::selection {\n    background-color: transparent; \n    color: inherit; \n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
